import React from 'react';
import { Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { RowExt } from '../styles';
import { ORANGE_WHITE } from '../styles/colors';

const Contact = () => {
  return (
    <>
      <RowExt center="xs" id="contact">
        <ContactText>
          CONTACT
        </ContactText>
      </RowExt>
      <RowExt center="xs">
        <EODText>Eod Code Sp. z o. o.</EODText>
      </RowExt>
      <RowExt center="xs" marginTop="md">
        <Col>
          <AdressText>
            ul.Chodkiewicza 6/15
          </AdressText>
          <AdressText>
            31-532 Kraków, Poland
          </AdressText>
          <AdressText>
            KRS:0000800125
          </AdressText>
          <AdressText>
            NIP:6751711644
          </AdressText>
        </Col>
      </RowExt>
      <RowExt center="xs" marginTop="sm">
        <a href="mailto:contact@pozew.app" target="_blank" rel="noreferrer">
          <AtButton>
            @
          </AtButton>
        </a>
      </RowExt>
    </>
  );
};

export default Contact;

const ContactText = styled.h3`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 64px;
  margin: 12px 0;
`;

const EODText = styled.p`
  font-family: 'Fira Mono', monospace;
  font-weight: 800;
  color:  ${ORANGE_WHITE};
  font-size: 24px;
  letter-spacing: 1.5px;
  text-align: center;
`;

const AdressText = styled.p`
  font-family: 'Fira Mono', monospace;
  font-weight: 600;
  color:  ${ORANGE_WHITE};
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
`;

export const AtButton = styled.button`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 52px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${ORANGE_WHITE};
`;
