import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import React, { useRef } from 'react';
import styled from 'styled-components';
import './App.css';
import Apps from './components/Apps';
import Contact from './components/Contact';
import Main from './components/Main';
import {
  GREY_DARK, ORANGE_LOGO_MEDIUM, ORANGE_WHITE,
} from './styles/colors';

function App() {
  const parallax = useRef<IParallax>(null);
  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <StyledParallax
        pages={3}
        ref={parallax}
      >
        <PageOne
          offset={0}
        >
          <Main
            scroll={scroll}
          />
        </PageOne>
        <PageTwoBg
          offset={1}
        />
        <PageTwoContent
          offset={1}
          speed={0.1}
        >
          <Apps />
        </PageTwoContent>
        <PageThreeBg
          offset={2}
        />
        <PageThreeContent
          offset={2}
          speed={1}
        >
          <Contact />
        </PageThreeContent>
      </StyledParallax>
    </div>
  );
}

export default App;

const StyledParallax = styled(Parallax)`
   background: ${GREY_DARK};
`;

const PageOne = styled(ParallaxLayer)`
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   background: ${ORANGE_LOGO_MEDIUM};
   color: ${ORANGE_WHITE};
`;

const PageTwoBg = styled(ParallaxLayer)`
   background: ${ORANGE_WHITE};
`;

const PageTwoContent = styled(ParallaxLayer)`
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
`;

const PageThreeBg = styled(ParallaxLayer)`
   background: ${GREY_DARK};
`;

const PageThreeContent = styled(PageTwoContent)`
   color: ${ORANGE_WHITE}
`;
