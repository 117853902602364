import styled from 'styled-components';
import { GREY_DARK, ORANGE_LOGO_MEDIUM, ORANGE_WHITE } from './colors';

export const TextButton = styled.button`
  background: transparent;
  font-size: 18px;
  border: none;
  font-weight: 700;
  color: ${ORANGE_WHITE};
  margin: 0 1em;
  padding: 0.25em 1em;
  cursor: pointer;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 700;
  :hover{
    background: ${ORANGE_WHITE};
    color: ${ORANGE_LOGO_MEDIUM};
  }
`;

export const MainButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  background: transparent;
  border-radius: 3px;
  border: none;
  background: ${ORANGE_LOGO_MEDIUM};
  border: 1px solid ${ORANGE_WHITE};
  margin: 0 1em;
  padding: 0.25em 1em;
  cursor: pointer;
  color:${ORANGE_WHITE};
  font-family: 'Fira Sans', sans-serif;
  :hover{
    background: ${ORANGE_WHITE};
    color: ${ORANGE_LOGO_MEDIUM};
  }
`;

export const VdButton = styled.button`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 42px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${ORANGE_WHITE};
  :hover { 
    transition: all .2s ease-in-out;
    transform: scale(1.2); 
  }
`;

export const SloganText = styled.span`
  font-family: 'Fira Mono', monospace;
  font-weight: 600;
  color:  ${GREY_DARK};
  font-size: 16px;
  letter-spacing: 2px;
  margin-left:24px;
  text-align: center;
  @media only screen and (max-width: 992px) {
    margin-left: 0;
    font-size: 14px;
  }
`;

export const ActionText = styled.span`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 42px;
  color:  ${GREY_DARK};
`;

export const PageWrapper = styled.div`
  width: 992px;
   @media only screen and (max-width: 768px) {
    width: 100%;
	}
`;
