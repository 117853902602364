import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import { ORANGE_LOGO_MEDIUM } from '../../styles/colors';

const Border = () => {
  return (
    <Col xs={1} sm={0} md={0} lg={2}>
      <Row center="xs">
        <CenterBorder>
          |
          <br />
          |
          <br />
          |
          <br />
          |
          <br />
          |
          <br />
          |
          <br />
        </CenterBorder>
      </Row>
    </Col>
  );
};

export default Border;

export const CenterBorder = styled.span`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 72px;
  color: ${ORANGE_LOGO_MEDIUM};
`;
