import React from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import as from '../../assets/apBadge.svg';
import gp from '../../assets/gpBadge.svg';
import dealemLogo from '../../assets/LogoDealem.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { RowExt } from '../../styles';
import {
  GREY_DARK,
} from '../../styles/colors';
import { ActionText, SloganText } from '../../styles/ui';

const Dealem = () => {
  const { width, height } = useWindowSize();
  if (width <= 768) {
    return (
      <>
        <RowExt middle="xs" center="xs" marginTop={38}>
          <DealemLogo
            src={dealemLogo}
          />
          <DealemText>
            Dealem
          </DealemText>
        </RowExt>
        {height > 667
          ? (
            <RowExt center="xs" marginTop={38}>
              <SloganText>
                {`${'"Contract your challenge"'}`}
              </SloganText>
            </RowExt>
          ) : null}
        <RowExt center="xs" marginTop={42}>
          <a href="https://play.google.com/store/apps/details?id=com.eodcode.dealem.bodhidharma" target="_blank" rel="noreferrer">
            <GoogleBadge
              src={gp}
            />
          </a>
        </RowExt>
        <RowExt center="xs" marginTop={24}>
          <a href="https://apps.apple.com/app/id1483466835" target="_blank" rel="noreferrer">
            <AppleBadge
              src={as}
            />
          </a>
        </RowExt>
      </>
    );
  }

  return (
    <>
      <RowExt middle="xs" center="xs">
        <DealemLogo
          src={dealemLogo}
        />
        <DealemText>
          Dealem
        </DealemText>
      </RowExt>
      <RowExt center="xs" marginTop={58}>
        <SloganText>
          {`${'"Contract your challenge"'}`}
        </SloganText>
      </RowExt>
      <RowExt center="xs" marginTop={60}>
        <DownloadText>
          {`${'Download it:'}`}
        </DownloadText>
      </RowExt>
      <RowExt center="xs" marginTop={42}>
        <a href="https://play.google.com/store/apps/details?id=com.eodcode.dealem.bodhidharma" target="_blank" rel="noreferrer">
          <GoogleBadge
            src={gp}
          />
        </a>
      </RowExt>
      <RowExt center="xs" marginTop={24}>
        <a href="https://apps.apple.com/app/id1483466835" target="_blank" rel="noreferrer">
          <AppleBadge
            src={as}
          />
        </a>
      </RowExt>
    </>
  );
};

export default Dealem;

const DealemLogo = styled(Img)`
  height: 82px;
  @media only screen and (max-width: 768px) {
    height: 62px;
	}
`;

const DealemText = styled.div`
  margin-left:34px;
  font-family: futura-pt, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 36px;
  color:  ${GREY_DARK};
  @media only screen and (max-width: 992px) {
    font-size: 28px;
    margin-left:28px;
  }
`;

export const SloganRow = styled(RowExt)`
    margin-top: 58px;
    @media only screen and (max-width: 768px) {
        margin-top: 32px;
  }

`;

const DownloadText = styled(ActionText)`
   margin-left: 32px;
   @media only screen and (max-width: 992px) {
    margin-left: 0;
	}
`;

const GoogleBadge = styled(Img)`
  height: 52px;
`;
const AppleBadge = styled(Img)`
  height: 58px;
`;
