import React from 'react';
import { Img } from 'react-image';
import styled from 'styled-components';
import pozewLogo from '../../assets/logo.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { RowExt } from '../../styles';
import { ORANGE_LOGO_MEDIUM } from '../../styles/colors';
import { ActionText, SloganText } from '../../styles/ui';
import { SloganRow } from './Dealem';

const Pozew = () => {
  const { width, height } = useWindowSize();
  if (width <= 768) {
    return (
      <>
        <RowExt middle="xs" center="xs">
          <PozewLogo src={pozewLogo} />
        </RowExt>
        {height > 667
          ? (
            <RowExt center="xs" marginTop={38}>
              <SloganText>
                {`${'"Więcej czasu na argumentację, automatyzuj uciążliwości."'}`}
              </SloganText>
            </RowExt>
          ) : null}
        <RowExt center="xs" marginTop={38} middle="xs">
          <LinkOrnaments>
            {`${'>'}`}
          </LinkOrnaments>
          <LinkText href="https://pozew.app" target="_blank" rel="noreferrer">
            pozew.app
          </LinkText>
          <LinkOrnaments>
            {`${'<'}`}
          </LinkOrnaments>
        </RowExt>
        <RowExt center="xs">
          <LinkOrnaments style={{
            marginTop: 8,
          }}
          >
            _ _ _
          </LinkOrnaments>
        </RowExt>
      </>
    );
  }
  return (
    <>
      <RowExt middle="xs" center="xs">
        <PozewLogo src={pozewLogo} />
      </RowExt>
      <SloganRow center="xs" marginTop={58}>
        <SloganText>
          {`${'"Więcej czasu na argumentację, automatyzuj uciążliwości."'}`}
        </SloganText>
      </SloganRow>
      <RowExt center="xs" marginTop={52}>
        <ActionText>
          {`${'Try it:'}`}
        </ActionText>
      </RowExt>
      <RowExt center="xs" marginTop={44} middle="xs">
        <ActionText>
          {`${'>'}`}
        </ActionText>
        <LinkText href="https://pozew.app" target="_blank" rel="noreferrer">
          pozew.app
        </LinkText>
        <ActionText>
          {`${'<'}`}
        </ActionText>
      </RowExt>
    </>
  );
};

export default Pozew;

const PozewLogo = styled(Img)`
  height: 56px;
  @media only screen and (max-width: 768px) {
    height: 42px;
	}
`;

const LinkText = styled.a`
  font-family: 'Fira Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color:  ${ORANGE_LOGO_MEDIUM};
  margin: 0 24px;
  padding-bottom: 6px;
  cursor: pointer;
  text-decoration: none;
  :hover { 
    transition: all .2s ease-in-out;
    transform: scale(1.1); 
  }
  @media only screen and (max-width: 768px) {
    height: 14px;
	}
`;

const LinkOrnaments = styled(ActionText)`
  font-size: 32px;
`;
