import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
import { RowExt } from '../styles';
import { PageWrapper } from '../styles/ui';
import Border from './Apps/Border';
import Dealem from './Apps/Dealem';
import Pozew from './Apps/Pozew';

const Apps = () => {
  const { width } = useWindowSize();

  if (width <= 768) {
    return (
      <>
        <Pozew />
        <Dealem />
      </>
    );
  }

  return (
    <Row center="xs">
      <PageWrapper>
        <RowExt center="xs">
          <LeftColumn
            xs={5}
          >
            <Dealem />
          </LeftColumn>
          <Border />
          <RightColumn
            xs={5}
          >
            <Pozew />
          </RightColumn>
        </RowExt>
      </PageWrapper>
    </Row>
  );
};

export default Apps;

const LeftColumn = styled(Col)`
    padding-top: 5vh;
`;

const RightColumn = styled(Col)`
    padding-top: 7vh;
`;
