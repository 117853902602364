import React, { FC } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
import { GRID_MD, RowExt } from '../styles';
import {
  MainButton, PageWrapper, TextButton, VdButton,
} from '../styles/ui';

const Main:FC<{
    scroll: any
}> = ({ scroll }) => {
  const { width } = useWindowSize();
  return (
    <>
      <Row center="xs">
        <PageWrapper>
          <RowExt middle="xs" marginTop="md">
            <Col xs={2} xsOffset={1}>
              <Logo>
                {'{...}'}
              </Logo>
            </Col>
            <Col xs={8}>
              <Row end="xs">
                <TextButton
                  onClick={() => { scroll(1); }}
                >
                  apps
                </TextButton>
                <AnchorLink href="#contact">
                  <MainButton
                    onClick={() => { scroll(2); }}
                  >
                    contact
                  </MainButton>
                </AnchorLink>
              </Row>
            </Col>
            <Col xs={1} />
          </RowExt>
        </PageWrapper>
      </Row>
      <TitleContainer>
        <RowExt center="xs">
          <Title>EOD CODE</Title>
        </RowExt>
        <RowExt center="xs">
          <SubTitle>software distribution</SubTitle>
        </RowExt>
        <RowExt center="xs" marginTop={width <= GRID_MD ? 'md' : 'bg'}>
          <VdButton
            onClick={() => { scroll(1); }}
          >
            V
          </VdButton>
        </RowExt>
      </TitleContainer>
      <div />
    </>
  );
};

export default Main;

const Title = styled.h1`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 84px;
`;

const SubTitle = styled.h2`
  font-family: 'Fira Mono', monospace;
  font-weight: 700;
  font-size: 18px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.span`
  font-family: rig-solid-bold-fill, sans-serif;
  font-weight: 700;
  font-size: 38px;
`;
